import React, { useEffect, useRef, useState } from 'react'
import Logo from "../../Assets/Logo.png";
import QuickLinksIcon from "../../Assets/QuickLinksIcon.png";
import "./Header.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import closeMenu from "../../Assets/close-menu.png";

const DarkHeaderMenuBG = ({ onClick }) => {
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent the click event from reaching the parent
    onClick();
  };

  return <div className='DarkHeaderMenuBG' onClick={handleClick} />;
};

export default function Header({ handleLogout }) {
	const uid = localStorage.getItem("uid")
	
	const navigate = useNavigate()
	const location = useLocation();
	const [showMenu, setShowMenu] = useState(false);

	const menuItems = [
		{ name: "News", to: "/#news" },
		{ name: "Knights System", to: "/#course" },
		{ name: "About Us", to: "/#about" }
	];

	const handleShowMenu = () => {
		setShowMenu(!showMenu)
	}

	return (
		<>
			<div className='Header'>
				<img onClick={() => navigate("/")} className='HeaderLogo' src={Logo} alt="Logo" />
				{location.pathname === "/" || (location.pathname !== "/" && !uid)?
					<div className='HeaderMenu'>
						<div className='QuickLinks'>
							{menuItems.map((item) => <div onClick={() => navigate(item.to)} key={item.name}>{item.name}</div>)}
						</div>
						<div onClick={handleShowMenu} className='QuickLinksIcon'>
							<img src={QuickLinksIcon} className='QuickLinksIcon' alt="QuickLinksIcon" />
						</div>
						<Link to="./auth">
						{location.pathname !== "/auth" && <button className='HeaderBtn'>Login</button>}
						</Link>
					</div>
					:
					<div className='HeaderMenu'>
						<div className='QuickLinks'>
							{menuItems.map((item) => <div onClick={() => navigate(item.to)} key={item.name}>{item.name}</div>)}
						</div>
						<div onClick={handleShowMenu} className='QuickLinksIcon'>
							<img src={QuickLinksIcon} className='QuickLinksIcon' alt="QuickLinksIcon" />
						</div>
						<button onClick={()=>{handleLogout(); navigate("/");}} className='HeaderBtn'>Logout</button>
					</div>
				}
			</div>
        <>
				{showMenu && <DarkHeaderMenuBG onClick={handleShowMenu} />}
          <div className={`HeaderMenuItems ${showMenu ? 'open' : ''}`}>
            <div onClick={handleShowMenu} className='CloseHeaderMenuItems'>
              <img src={closeMenu} alt='' />
            </div>
            {menuItems.map((item) => (
              <div
                className='HeaderMenuItem'
                onClick={() => {
                  navigate(item.to);
                  setShowMenu(false);
                }}
                key={item.name}
              >
                {item.name}
              </div>
            ))}
          </div>
        </>
		</>
	)
}
