import React, { useEffect, useRef, useState } from "react";
import { getData } from "../Fetch";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./LevelPage.css";
import "../Components/HomePage/Course.css";
import Tri from "../Assets/Tri.svg";
import Loading from "../Components/General/Loading";

function LevelPage() {
  const { lid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [websiteData, setWebsiteData] = useState(null);
  const [levelData, setLevelData] = useState(null);
  const courseLevelContainerRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    const uid = localStorage.getItem("uid");
    const fetchData = async () => {
      try {
        const websiteData = await getData(`website/levels/${lid}`);
        setWebsiteData(websiteData);
        const userData = uid? await getData(`users/${uid}`) : null;
        if (!userData) {
          if (!websiteData.public) {
            alert("Error, You don't have access!");
            navigate("/");
            return
          }
          setLoading(false);
        }
        else if (userData.level < websiteData.order) {
          alert("Error, You don't have access!");
          navigate("/knights-system");
          setLoading(false);
          return;
        }
        const levelData = await getData(`levels/${lid}/videos`);
        setLevelData(levelData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [lid, navigate]);

  useEffect(() => {
    if (courseLevelContainerRef.current) {
      const numberLine = courseLevelContainerRef.current.querySelector(".NumberLine");
      const numberSquare = courseLevelContainerRef.current.querySelector(".NumberSquare");
      const containerHeight = courseLevelContainerRef.current.offsetHeight;

      // Get the computed width of NumberSquare
      const numberSquareWidth = parseFloat(window.getComputedStyle(numberSquare).width);

      // Set the height of the NumberLine dynamically based on the width of NumberSquare
      numberLine.style.height = `${containerHeight - numberSquareWidth - 20}px`;
    }
  }, [loading, imageLoaded]);

  // Function to handle the image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div>
      {loading && <Loading />}

      {!loading && websiteData && (
        <div className="LevelPage">
          <div className="CourseLevelContainer" ref={courseLevelContainerRef}>
            <div className='LevelNumberContainer'>
              <div className='NumberSquare'>{websiteData.order}</div>
              <div className='NumberLine'></div>
              <div className="NumberLineCircle"></div>
            </div>
            <div className='CourseLevelContent'>
              <div className='CourseLevelTitleOff'>{websiteData.title}</div>
              <div className='CourseLevelBody'>{websiteData.body}</div>
              <div className="ThumbnailLockContainerOff">
                <img
                  onLoad={handleImageLoad}
                  className='CourseLevelThumbnailOff'
                  src={websiteData.thumbnail}
                  alt={websiteData.thumbnail}
                />
              </div>
            </div>
          </div>
          <div className="LevelVideos">
            {levelData &&
              Object.entries(levelData)
                .sort((a, b) => a.order - b.order)
                .map(([vid, video], index) => (
                  <div onClick={() => navigate(`/knights-system/lvl/${lid}/vid/${vid}`)} className="VideoListTitleContainer">
                    <div className="VideoListCircle">{index + 1}</div>
                    <div>
                      <div className="VideoListTitle">{video.title}</div>
                      <div className="WatchVideo">
                        <div>Watch Video</div>
                        <img src={Tri} alt="" />
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}

      {!loading && (!websiteData) && (
        <p>No data available. Please try again later.</p>
      )}
    </div>
  );
}

export default LevelPage;
