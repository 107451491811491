import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getData } from './Fetch';
import { initializeApp } from 'firebase/app';

import HomePage from "./Pages/HomePage"
import KnightsSystem from "./Pages/KnightsSystem"
import LevelPage from "./Pages/LevelPage"
import VideoPage from "./Pages/VideoPage"
import AuthPage from './Pages/AuthPage';
import Footer from './Components/General/Footer';
import Header from './Components/General/Header';

import Loading from './Components/General/Loading';

export const firebaseConfig = {
  apiKey: "AIzaSyAN4OBSD0OYlmqCWglorlF6gJn2AdSTqSk",
  authDomain: "gc-edu.firebaseapp.com",
  databaseURL: "https://gc-edu-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gc-edu",
  storageBucket: "gc-edu.appspot.com",
  messagingSenderId: "152395550515",
  appId: "1:152395550515:web:ea0f3c8c13a9b71569b846",
  measurementId: "G-KGYZKS1CHZ",
  useDeviceLanguage: false,
};

const firebaseApp = initializeApp(firebaseConfig);

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const handleLogout = async () => {
    const auth = getAuth(firebaseApp);
    try {
      await signOut(auth).then(() => {
        setIsAuth(false);
        localStorage.removeItem('uid');
        alert("Logout Successful")
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  useEffect(() => {

    const uid = localStorage.getItem("uid");

    if (!uid) {
      setIsAuth(false);
      setLoading(false);
      return
    }

    const auth = getAuth(firebaseApp);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const data = await getData(`users/${uid}`);
          if (data) {
            setIsAuth(true);
          } else {
            setIsAuth(false);
            handleLogout()
            alert("No user data")
          }
        } catch (error) {
          setIsAuth(false);
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setIsAuth(false);
        localStorage.removeItem("uid")
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setIsAuth]);

  const memberRoutes = [
    { path: '/', element: <HomePage /> },
    { path: "", element: <HomePage /> },
    { path: '/auth', element: <Navigate to="/knights-system" replace /> },
    { path: "/knights-system", element: <KnightsSystem /> },
    { path: '/knights-system/lvl/:lid', element: <LevelPage /> },
    { path: '/knights-system/lvl/:lid/vid/:vid', element: <VideoPage /> },
  ]

  const userRoutes = [
    { path: '/', element: <HomePage /> },
    { path: '', element: <HomePage /> },
    { path: '/auth', element: <AuthPage setIsAuth={setIsAuth} /> },
    { path: '/knights-system/lvl/:lid', element: <LevelPage /> },
    { path: '/knights-system/lvl/:lid/vid/:vid', element: <VideoPage /> },
  ];

  const routes = isAuth ? memberRoutes : userRoutes;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <div className='BlurCircle TopRight'></div>
      <div className='BlurCircle TopLeft'></div>
      <Router>
        <Header handleLogout={handleLogout ? handleLogout : null} />
        <Routes>
          {routes && routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
