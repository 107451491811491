import React from 'react'
import { useNavigate } from 'react-router-dom'
import CountUp from 'react-countup'
import "./Welcome.css"

import MembersNumber from "../../Assets/MembersNumber.svg"
import EventsNumber from "../../Assets/EventsNumber.svg"
import CountriesNumber from "../../Assets/CountriesNumber.svg"
import GetStarted from "../../Assets/GetStarted.svg";


export default function Welcome({ websiteData }) {
	const navigate = useNavigate()
	return (
		<div className='Welcome'>
			<div className='WelcomeTitle'>
				<div>
					<div>ALL FOR ONE & ONE FOR ALL</div>
				</div>
				<p>
					An international entrepreneurial community and organization deeply rooted in trading,
					investing, and entrepreneurship, fostering connections and knowledge-sharing to empower
					individuals in navigating the evolving world of digital assets and financial market
				</p>
				<div className='WelcomeBtns'>
					<button onClick={() => navigate("/auth")}><div className='GetStarted'><div>Get Started</div><img src={GetStarted} alt="GetStarted" /></div></button>
					<button onClick={() => window.location.replace("https://www.instagram.com/gc.knights/")} >Contact Us</button>
				</div>
			</div>

			<div className='WelcomeVideo'>
				{websiteData.welcome && websiteData.welcome.video && (
					<iframe
						src={websiteData.welcome.video}
						allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
						title="welcome-video"
						style={{ width: '100%', height: '100%', display: 'block', border: 'none', borderRadius: 'min(15px, calc(100vw * (7 / 950)))' }}
					></iframe>
				)}
			</div>
			
			<div className='WelcomeNumbers'>
				<div className='WelcomeNumbersItem'>
					<div className='Number'>
						<CountUp end={5000} duration={8} />+
					</div>
					<div className='NumberFooter'>
						<img src={MembersNumber} alt="MembersNumber" />
						<div>Total Members</div>
					</div>
				</div>
				<div className='WelcomeNumbersItem'>
					<div className='Number'>
						<CountUp end={60} duration={8} />+
					</div>
					<div className='NumberFooter'>
						<img src={EventsNumber} alt="EventsNumber" />
						<div>Events & Campaigns</div>
					</div>
				</div>
				<div className='WelcomeNumbersItem'>
					<div className='Number'>
						<CountUp end={27} duration={8} />+
					</div>
					<div className='NumberFooter'>
						<img src={CountriesNumber} alt="CountriesNumber" />
						<div>Countries</div>
					</div>
				</div>
			</div>

		</div>
	)
}
