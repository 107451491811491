import React, { useEffect, useState } from "react";
import { getData } from "../Fetch";
import "./HomePage.css"
import Welcome from "../Components/HomePage/Welcome";
import News from "../Components/HomePage/News";
import Course from '../Components/HomePage/Course';
import About from "../Components/HomePage/About";
import Loading from "../Components/General/Loading";
import { useLocation } from "react-router-dom";

function HomePage() {
	const { pathname, hash, key } = useLocation();
	const [loading, setLoading] = useState(true);
	const [websiteData, setWebsiteData] = useState(null);
	const [userData, setUserData] = useState(null);
	const uid = localStorage.getItem("uid")

	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		} else {
			// else scroll to id
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({
						behavior: 'smooth', // Add smooth scrolling behavior
					});
				}
			},);
		}
	}, [pathname, hash, key]);

	useEffect(() => {
		getData("website")
			.then((data) => {
				setWebsiteData(data);
			})
			.finally(() => {
				setLoading(false);
			});
		if (uid) {
			getData(`users/${uid}`)
				.then((data) => {
					setUserData(data);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, []);

	return (
		<div className="HomePage">
			{loading && <Loading />}

			{!loading && websiteData && (
				<div>
					<Welcome websiteData={websiteData} />
					<div id="news">
						<News websiteData={websiteData} />
					</div>
					<div id="course">
						<Course userData={userData} websiteData={websiteData} />
					</div>
					<div id="about">
						<About />
					</div>
				</div>
			)}

			{!loading && !websiteData && <p>No data available.</p>}
		</div>
	);
}

export default HomePage;
