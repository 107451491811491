import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getData } from "../Fetch";
import "./AuthPage.css"
import { useNavigate } from "react-router-dom";

function AuthPage({ setIsAuth }) {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false);
	const [inputId, setInputId] = useState("");

	const handleLogin = async (e) => {
		e.preventDefault();

		if (!inputId) {
			alert("Please enter your ID");
		}

		setLoading(true)

		try {
			const userData = await getData(`tokens/${inputId}`);
			if (!userData) {
				alert("invalid ID, please try again")
				setLoading(false)
			}
			else {
				const auth = getAuth();
				const userCredential = await signInWithEmailAndPassword(auth, userData.email, userData.password);
				const user = userCredential?.user;

				if (user) {
					const userData = await getData(`users/${inputId}`);
					if (userData) {
						localStorage.setItem('uid', inputId);
						setIsAuth(true);
						alert('Login successful!');
						navigate("/knights-system")
					} else {
						// Admin data not found, handle accordingly (e.g., redirect to an error page)
						alert('user data not found.');
						localStorage.removeItem('uid');

						await signOut(auth);

						setIsAuth(false);
					}
					setLoading(false)
				} else {
					// User is null, login credentials are invalid
					alert('Invalid email or password. Please try again.');
					setIsAuth(false);
					setLoading(false)
				}
			}
		} catch (error) {
			console.error('Error during login:', error);
			// Handle other errors (e.g., network issues) as needed
			alert('An error occurred during login. Please try again later.');
			setIsAuth(false);
			setLoading(false)
		}
	};

	return (
		<form onSubmit={handleLogin}>
			<div className="AuthSlogan">Are you a member?</div>
			<div className="AuthTitle">Enter your ID</div>
			<div className="AuthContainer">
				<input
					required
					type="text"
					value={inputId}
					onChange={(e) => setInputId(e.target.value)}
					placeholder="Enter your ID"
				/>
				<button type="submit" disabled={loading}>
					{loading ? "Logging in..." : "Login"}
				</button>
			</div>
		</form>
	);
}

export default AuthPage;
