import React from 'react'
import { useLocation } from 'react-router-dom';
import "./Footer.css"
import Logo from "../../Assets/Logo.png"

export default function Footer() {
	const location = useLocation();
	const footerClasses = location.pathname === "/" ? "Footer FooterBottom" : "Footer FooterTop";

	return (
		<div className="Footer">
			<img src={Logo} alt="Logo" />
			<div>© 2024 Knights | All Rights Reserved</div>
		</div>
	)
}
