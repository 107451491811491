import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';

export const getData = async (endpoint) => {
  const database = getDatabase();
  const auth = getAuth();

  try {
    // Get the current authenticated user
    const user = auth.currentUser;
    const dataRef = ref(database, `Api/${endpoint}`);
    if (user) {
      const authToken = await user.getIdToken();
      let snapshot = await get(dataRef, { auth: authToken });
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    } else {
      let snapshot = await get(dataRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    }
  } catch (error) {
    throw error;
  }
};
