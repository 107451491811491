import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import NextBtn from "../../Assets/NextBtn.svg"
import PrevBtn from "../../Assets/PrevBtn.svg"

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import './News.css';

function News({ websiteData }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [newsData, setNewsData] = useState(Object.values(websiteData.news)?.sort((a, b) => b.timestamp - a.timestamp));
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOverlayClick = (event) => {
      const popup = popupRef.current;
      if (popup && !popup.contains(event.target)) {
        closePopup();
      }
    };

    const overlay = document.querySelector('.popup');
    overlay.addEventListener('click', handleOverlayClick);

    const swiper = document.querySelector('.swiper_container').swiper;
    swiper.on('slideChange', () => {
      setActiveSlideIndex(swiper.realIndex);
    });

    return () => {
      overlay.removeEventListener('click', handleOverlayClick);
    };
  }, []);

  function closePopup() {
    const popup = popupRef.current;
    popup.style.opacity = '0';
    popup.style.visibility = 'hidden';
  }

  function handleImageClick(newsItem) {
    const popup = popupRef.current;

    if (popup.style.visibility === 'visible') {
      closePopup();
    } else {
      popup.style.opacity = '1';
      popup.style.visibility = 'visible';
      popup.querySelector('img').src = newsItem.image;
    }
  }

  return (
    <div className="Slider">
      <div className='NewsBlurCircle NewsCircleRight'></div>
      <div className='NewsBlurCircle NewsCircleLeft'></div>
      {newsData && (
        <div className="container">
          <h1 className="heading">News and Updates</h1>
          <div className='ActiveSlideTitle'>
            {newsData[activeSlideIndex] &&
              newsData[activeSlideIndex].title
              ? newsData[activeSlideIndex].title
              : null}
          </div>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            spaceBetween={-190}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            slidesPerView={2}
            className="swiper_container"
          >
            {newsData
              .map((newsItem, index) => (
                <SwiperSlide key={newsItem.timestamp}>
                  <img
                    src={newsItem.image}
                    alt={`${newsItem.title} Image`}
                    onClick={() => { if (activeSlideIndex === index) handleImageClick(newsItem) }}
                  />
                </SwiperSlide>
              ))}
            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <img src={PrevBtn} alt="PrevBtn" />
              </div>
              <div className="swiper-pagination"></div>
              <div className="swiper-button-next slider-arrow">
                <img src={NextBtn} alt="NextBtn" />
              </div>
            </div>
          </Swiper>
          <div ref={popupRef} className="popup" onClick={(event) => event.stopPropagation()}>
            <img src="" alt="Popup Image" />
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default News;