import React, { useEffect, useRef, useState } from 'react';
import "./Course.css";
import { useNavigate } from 'react-router-dom';

export default function Course({ userData, websiteData }) {
  return (
    <div className='CourseLevels'>
      <div className='CourseTitle'>Knights System</div>
      {websiteData && websiteData.levels &&
        Object.entries(websiteData.levels)
          .sort((a, b) => a.order - b.order)
          .map(([lid, level], index) => (
            <CourseLevel key={index} userData={userData} lid={lid} level={level} isLast={index === Object.keys(websiteData.levels).length - 1} />
          ))}
    </div>
  );
}

function CourseLevel({ level, isLast, lid, userData }) {
  const navigate = useNavigate()
  const courseLevelContainerRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const numberLine = courseLevelContainerRef.current.querySelector('.NumberLine');
    const numberSquare = courseLevelContainerRef.current.querySelector('.NumberSquare');
    const containerHeight = courseLevelContainerRef.current.offsetHeight;

    // Get the computed width of NumberSquare
    const numberSquareWidth = parseFloat(window.getComputedStyle(numberSquare).width);

    // Set the height of the NumberLine dynamically based on the width of NumberSquare
    numberLine.style.height = isLast ? '0' : `${containerHeight - numberSquareWidth}px`;
  }, [isLast, imagesLoaded]);

  // Function to handle the image load event
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  return (
    <div className='CourseLevelContainer' ref={courseLevelContainerRef}>
      <div className='LevelNumberContainer'>
        <div
          className='NumberSquare'
          onClick={() => level.public || (userData && level.order <= userData.level) ? navigate(`/knights-system/lvl/${lid}`) : navigate(`/auth`)}
        >
          {level.order}
        </div>
        <div className='NumberLine'></div>
      </div>
      <div className='CourseLevelContent'>
        <div
          className='CourseLevelTitleOff'
          onClick={() => level.public || (userData && level.order <= userData.level) ? navigate(`/knights-system/lvl/${lid}`) : navigate(`/auth`)}
        >{level.title}</div>
        <div className='CourseLevelBody'>{level.body}</div>
        <div className="ThumbnailLockContainer">
          <img
            className='CourseLevelThumbnailOff'
            src={level.thumbnail}
            alt={level.thumbnail}
            onLoad={handleImageLoad}
            onClick={() => level.public || (userData && level.order <= userData.level) ? navigate(`/knights-system/lvl/${lid}`) : navigate(`/auth`)}
          />
        </div>
        {level.public &&
          <button
            className='AppBtn'
            onClick={() => level.public ? navigate(`/knights-system/lvl/${lid}`) : navigate(`/auth`)}
          >
            Watch Now
          </button>}
      </div>
    </div>
  );
}
