import React, { useEffect, useState } from "react";
import { getData } from "../Fetch";
import { useParams, Link, useNavigate } from "react-router-dom";
import Loading from "../Components/General/Loading";

import "./VideoPage.css"
import File from "../Assets/file.svg"
import Next from "../Assets/NextBtn.svg"
import Prev from "../Assets/PrevBtn.svg"

function VideoPage() {
	window.scrollTo(0, 0)
	const { lid, vid } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [videoData, setVideoData] = useState(null);
	const [videosList, setVideosList] = useState([]);

	useEffect(() => {
		const uid = localStorage.getItem("uid");

		const fetchData = async () => {
			try {
				const levelData = await getData(`website/levels/${lid}`);
				const userData = uid? await getData(`users/${uid}`) : null;
				if (!userData) {
          if (!levelData.public) {
            alert("Error, You don't have access!");
            navigate("/");
            setLoading(false);
            return
          }
        }
        else if (userData.level < levelData.order) {
					alert("Error, You don't have access!");
					navigate("/knights-system");
					setLoading(false);
					return;
				}
				const videosData = await getData(`levels/${lid}/videos`);
				setVideosList(Object.values(videosData).map(video => ({
					...video, // Spread existing properties
					vid: Object.keys(videosData).find(key => videosData[key] === video)
				})).sort((a, b) => a.order - b.order));

				const videoData = await getData(`levels/${lid}/videos/${vid}`);
				setVideoData(videoData);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setLoading(false);
			}
		};
		fetchData();
	}, [lid, vid, navigate]);

	const currentIndex = videosList.findIndex((video) => video.vid === vid);

	const navigateToPrevious = () => {
		const previousIndex = currentIndex - 1;
		if (previousIndex >= 0) {
			const previousVideoId = videosList[previousIndex].vid;
			navigate(`/knights-system/lvl/${lid}/vid/${previousVideoId}`);
			setLoading(true)
		}
	};

	const navigateToNext = () => {
		const nextIndex = currentIndex + 1;
		if (nextIndex < videosList.length) {
			const nextVideoId = videosList[nextIndex].vid;
			navigate(`/knights-system/lvl/${lid}/vid/${nextVideoId}`);
			setLoading(true)
		}
	};

	if (loading) return <Loading />
	else if (!loading && !videoData)
		return <p>404 No data available. Please try again later.</p>

	return (
		<div className="VideoPage">
			<div className="VideoPageTitle">
				<div className="VideoPageTitleOrder">{videoData.order}</div>
				<div className="VideoPageTitleText">{videoData.title}</div>
			</div>

			<div className='VideoPagePlayer'>
        {videoData.video &&<iframe
          src={videoData.video}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="welcome-video"
          style={{ width: '100%', height: '100%', display: 'block', border: 'none', borderRadius: 'min(15px, calc(100vw * (7 / 950)))' }}
        ></iframe>}
      </div>
			<div className="VideoPageBody">{videoData.body}</div>
			{videoData.attachments &&
				<div className="VideoPageAttachments">
					{Object.values(videoData.attachments).map((file, index) => (
					<Link className="AttachmentFile" key={index} to={file.file}>
						<img src={File} alt={file.display} />
					</Link>
					))}
				</div>
			}
			<div className="VideoBtnContainer">
				<div className="VideoBtn">
					<img className={currentIndex === 0 ? "BtnDisabled" : ""} src={Prev} alt="Prev" onClick={navigateToPrevious} />
					<img className={currentIndex === Object.values(videosList).length - 1 ? "BtnDisabled" : ""} src={Next} alt="Next" onClick={navigateToNext} />
				</div>
			</div>
		</div>
	);
}

export default VideoPage;
