import React from 'react'
import "./About.css"
import AboutImg from "../../Assets/About.png"
import WhatsApp from "../../Assets/WhatsApp.svg"
import Instagram from "../../Assets/Instagram.svg"
import Call from "../../Assets/Call.svg"

export default function About() {
	return (
		<div className='About'>
			<div className='NewsBlurCircle NewsCircleRight'></div>
			<div className='NewsBlurCircle NewsCircleLeft'></div>
			<div className='AboutContainer'>
				<div className='AboutContent'>
					<div className='AboutTitle'>About Us</div>
					<div className='AboutBody'>
						An international entrepreneurial community and organization deeply rooted in trading,
						investing, and entrepreneurship, fostering connections and knowledge-sharing to
						empower individuals in navigating the evolving world of digital assets and
						financial markets.
					</div>
					<div className='AboutIcons'>
						<img onClick={() => window.location.replace("https://www.instagram.com/gc.knights/")} src={Instagram} alt="Instagram" />
					</div>
				</div>
				<div className='AboutImgContainer'>
					<img src={AboutImg} alt="About" />
				</div>
			</div>
		</div>
	)
}
