import React, { useEffect, useRef, useState } from "react";
import { getData } from "../Fetch";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/General/Loading";

import Lock from "../Assets/Lock.svg";

export default function KnightsSystem() {
  const [loading, setLoading] = useState(true);
  const [levelsData, setLevelsData] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    const fetchData = async () => {
      try {
        const levelsData = await getData("website/levels");
        setLevelsData(levelsData);
        const userData = await getData(`users/${uid}`);
        setUser(userData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (loading) return <Loading />;

  return (
    <div style={{ padding: 15 }}>
      <div className=""></div>
      <div className="CourseTitle">Knights System</div>
      {levelsData &&
        Object.entries(levelsData)
          .sort((a, b) => a.order - b.order)
          .map(([lid, level], index) => (
            <CourseLevel
              lid={lid}
              key={index}
              user={user}
              level={level}
              isLast={index === Object.keys(levelsData).length - 1}
            />
          ))}
    </div>
  );
}

function CourseLevel({ user, level, isLast, lid }) {
  const courseLevelContainerRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const numberLine = courseLevelContainerRef.current.querySelector(".NumberLine");
    const numberSquare = courseLevelContainerRef.current.querySelector(".NumberSquare");
    const containerHeight = courseLevelContainerRef.current.offsetHeight;

    // Get the computed width of NumberSquare
    const numberSquareWidth = parseFloat(window.getComputedStyle(numberSquare).width);

    // Set the height of the NumberLine dynamically based on the width of NumberSquare
    numberLine.style.height = isLast ? "0" : `${containerHeight - numberSquareWidth}px`;
  }, [isLast, imageLoaded]);

  // Function to handle the image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={user.level < level.order ? "CourseLevelContainer CourseLevelLocked" : "CourseLevelContainer"} ref={courseLevelContainerRef}>
      <div className="LevelNumberContainer">
        <div className="NumberSquare">{level.order}</div>
        <div className="NumberLine"></div>
      </div>
      <div className="CourseLevelContent">
        <Link to={user.level >= level.order ? `./lvl/${lid}` : ""}>
          <div className="CourseLevelTitle">{level.title}</div>
        </Link>
        <div className="CourseLevelBody">{level.body}</div>
        <div className="ThumbnailLockContainer">
          {user.level < level.order && <img className="ThumbnailLock" src={Lock} alt={level.thumbnail + " Locked"} />}
          <img
            onLoad={handleImageLoad}
            onClick={() => (user.level >= level.order ? navigate(`/knights-system/lvl/${lid}`) : "")}
            className="CourseLevelThumbnail"
            src={level.thumbnail}
            alt={level.thumbnail}
          />
        </div>
      </div>
    </div>
  );
}
